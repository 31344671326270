import XLSX from "xlsx";
class Excel {

    constructor(props, sheetName = "Dados") {
        this.workBook = XLSX.utils.book_new();
        this.workBook.Props = props;
        this.workBook.SheetNames.push(sheetName);
        this.data = null;
    }

    newSheet(name) {
        this.workBook.SheetNames.push(name);
    }

    setData(data, sheetName = "Dados") {
        this.data = data;
        var ws = XLSX.utils.aoa_to_sheet(data);
        this.workBook.Sheets[sheetName] = ws;
    }

    setHeader(header) {
        this.data.unshift(header);
        this.setData(this.data);
    }

    getBuffer() {
        let excel = XLSX.write(this.workBook, { bookType: "xlsx", type: "binary" });
        var buffer = new ArrayBuffer(excel.length); //convert excel to arrayBuffer
        var view = new Uint8Array(buffer); //create uint8array as viewer
        for (var i = 0; i < excel.length; i++) view[i] = excel.charCodeAt(i) & 0xff; //convert to octet
        return buffer;
    }

    exportToBlob() {
        var blob = new Blob([this.getBuffer()], {
            type: "application/octet-stream"
        });
        return blob;
    }
}

class Csv {
    constructor(delimiter = ";", textDelimiter = "'") {
        this.delimiter = delimiter;
        this.textDelimiter = textDelimiter;
        this.data = null;
    }

    setData(data) {
        this.data = data;
    }

    setHeader(header) {
        this.data.unshift(header);
    }

    mountData() {
        let csv = "";
        this.data.forEach(line => {
            let items = []
            line.forEach(item => {
                var el = "";
                if (item == null)
                    el = "";
                else if (typeof item == 'number')
                    el = String(item);
                else
                    el = this.textDelimiter + String(item) + this.textDelimiter

                items.push(el);
            });
            csv += items.join(this.delimiter) + "\r\n";
        });
        return csv;
    }

    exportToBlob() {
        var csv = this.mountData();
        return new Blob(["\ufeff" + csv], { type: "text/csv" });
    }
}

export { Excel, Csv }